// App custom files

// Custom override to remove green check marks on valid form fields
.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none; // Removes the green check background image
}

// Custom override to remove green check marks on valid select fields
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size='1'],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size='1'] {
  --bs-form-select-bg-icon: none; // This removes the background icon
  padding-right: 2.25rem; // Adjust padding if necessary
}

// Custom Media Query for screens 340px and lower
@media (max-width: 340px) {
  .navbar-expand-lg #logo-container a.logo p.logo-text {
    font-size: 0.64rem;
  }
  #header .logo {

    .logo-img {
      width: 30%;
    }
  }
}
